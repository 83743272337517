var generic = generic || {};
var Model = Model || {};

/**
  * Singleton data model that handles collecting door inventory data for a single or set of skus.
  * This model can also be used to only call door data if needed.
  * When you pass a sku, or set of skus, with the door query, a door_inventory data object will be included.
  * Also, the rpc method changes from 'locator.doorsandevents' to 'locator.doorsandskustatus' when a sku is passed.
  * On success, the event 'DoorsInventory:query:success' is fired and other panels can listen in on that event.
  * Also available are helper methods that when passed a door id and sku id, can return the inventory object or just let
  *   you know if a sku is in-stock or not.
  * NOTE: Each store has a safety stock number and that safety stock is included with the totals coming from the RPC call.
  * You do not need to factor in the saftey stock when computing if a stock is availble or not.
*/
Model.DoorsInventory = (function() {
  var that = {};

  that.data = null;
  that.defaultZipRegEx = '(^\\d{5}(-\\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnpstvxy]{1}\\d{1}[A-Za-z]{1} ?\\d{1}[A-Za-z]{1}\\d{1})$';
  that.storeTypesReq = ['MAC', 'MAC_PRO', 'MAC Pro', 'Mac Pro', 'MAC PRO', 'Mac PRO'];
  if (Drupal.settings.globals_variables.enable_partner_count) {
    that.storeTypesReq.push('Partner Store');
    that.storeTypePartner = that.storeTypesReq.slice(-1)[0];
  }

  /**
    * Method used to call the load doors and inventory data query.
    * When a sku, or array of skus, is passed, it will return an inventory data object with the door data.
    * This is done by passing the 'use_header' flag, which tells the backend to get the location data via the akamai header data.
    * The default radius for the doors query is within a 50 mile radius unless an optional radius is passed.
    * The queryFieldss are set within the model using the queryFields array object.
    * You can pass an optional callback function or use the event 'DoorsInventory:query:success' on success to receive results data.
    * Here is an example of the return data object with 'door_inventory'.
    *   "value":{
    *     "driving_directions":"yes",
    *     "longitude":"-117.9073244",
    *     "count":8,
    *     "country_code":"us",
    *     "latitude":"33.6834142",
    *     "doors":[446387,185639,165341,165395,165411,429482,429489,429376],
    *     "door_inventory": { ..Inventory objects by door.. },
    *     "results":{ ..Result door data.. }
    *   }
  */
  that.load = function(args) {

    var that = this;
    var _errors = [];
    var _args = args || {};
    var _zip = _args.zip || null;
    var _latitude = _args.latitude || null;
    var _longitude = _args.longitude || null;
    var _radius = _args.radius || 10;
    var _state = _args.state || null;
    var _city = _args.city || null;
    var _region_id = _args.region_id || null;
    var _store_types = _args.inv_store_types ? _args.inv_store_types.split(',') : that.storeTypesReq;
    var _display = ( _args.display && _args.display != '_' ) ? _args.display : null;
    var _skus = _.isArray(_args.skus) ? _args.skus : [_args.skus];
    var _callback = _args.callback || null;
    var _useHeader = _args.useHeader || false;
    var _safetyStock = _args.safetyStock || 0;
    var _uom = _args.uom || 'km';
    if (!Drupal.settings.globals_variables.enable_prod_store_check_kilometer) {
      _uom = _args.uom || 'mile';
    }


    // Trim _store_types to account for CMS spacing.
    _store_types = _.map(_store_types, _.trim);

    var queryArgs = {
      params : [{
        'fields' : that.queryFields,
        'radius' : _radius,
        'display' : _display,
        'region_id' : _region_id,
        'inv_store_types' : _store_types,
        'skus' : _skus,
        'safetyStock' : _safetyStock,
        'uom': _uom
      }]
    };
    // console.log(queryArgs);
    if (_zip) {
      // If zip is entered, check for valid zip.
      if (Drupal.settings.globals_variables.enable_iv_store_locator) {
        queryArgs.params[0].latitude = _latitude;
        queryArgs.params[0].longitude = _longitude;
      } else {
        var zipRegEx = that.getLocalisedZipRegEx(Drupal.settings.globals_variables);
        if (zipRegEx.test(_zip)) {
          queryArgs.params[0].zip = _zip;
        } else {
          _errors.push({ key: 'err_invalid_zip' });
        }
      }
    } else if ( _state && _city ) {
      // If state and city are entered
      queryArgs.params[0].state = _state;
      queryArgs.params[0].city = _city;
    } else if ( _useHeader ) {
      // User header param is set.
      queryArgs.params[0].use_header = 1;
    } else {
      // When all else fails.
      _errors.push({ key : 'err_missing_zip' });
    }

    // Override to force the location for headers.
    var queryParams = document.location.search;
    var hasTestingOverride = queryParams.match(/OVERRIDE_CITY=(\w*)/);
    if (hasTestingOverride && hasTestingOverride[1] && ( !_zip && !_state && !_city )) {
      queryArgs.params[0].fake_mode = hasTestingOverride[1]; // Param for testing to be removed
      queryArgs.params[0].show_message = 1; //Param for testing to be removed
      queryArgs.params[0].use_header = 1;
    }

    queryArgs.onSuccess = function( results ) {
      if (typeof _callback == 'function') {
        _callback(results);
      }
      $(document).trigger('DoorsInventory:query:success', results);
    };

    queryArgs.onFailure = function( results ) {

      if (_callback) {
        _callback(results);
      }
      $(document).trigger('DoorsInventory:query:failure', results);
    };

    if ( _errors[0] ) {
       $(document).trigger('DoorsInventory:query:failure', {
        errors : _errors
       });
    } else {

      // JSON-RPC request
      that.query(queryArgs);

    }
  };

  /**
    * Method used to call the load only door query.
    * Uses the 'use_header' flag to tell the backend to get the location data via the akamai header data.
    * The default radius for the doors query is within a 50 mile radius unless an optional radius is passed.
    * The queryFieldss are set within the model using the queryFields array object.
    * You can pass an optional callback function or use the event
    *   'DoorsInventory:queryAreaDoors:success' on success to receive results data.
    * Here is an example of the return data object without 'door_inventory'.
    *   "value":{
    *     "driving_directions":"yes",
    *     "longitude":"-117.9073244",
    *     "count":8,
    *     "country_code":"us",
    *     "latitude":"33.6834142",
    *     "doors":[446387,185639,165341,165395,165411,429482,429489,429376],
    *     "results":{ ..Result door data.. }
    *   }
  */
  that.loadDoorsOnly = function(args) {

    var that = this;
    var _args = args || {};
    var _radius = _args.radius || 100;
    if (Drupal.settings.globals_variables.enable_iv_store_locator) {
      _radius = _args.radius || 25;
    }
    var _callback = _args.callback || null;
    var _skus = ( _.isArray(_args.skus)) ? _args.skus : [_args.skus];

    var queryArgs = {
      params : [{
        'fields' : that.queryFields,
        'radius' : _radius,
        'skus' : _skus,
        'use_header' : 1
      }]
    };
    if (args.region_id) {
      queryArgs.params[0].region_id = args.region_id;
    }
    queryArgs.onSuccess = function( results ) {
      if (_callback) {
        _callback(results);
      }

      $(document).trigger('DoorsInventory:query:success', results);
    };

    queryArgs.onFailure = function( results ) {
      if (_callback) {
        _callback(results);
      }
      $(document).trigger('DoorsInventory:query:failure', results);
    };

    that.query(queryArgs);
  };

  /**
    * Method responsible for making the generic jsonrpc fetch to backend.
    * If skus are passed, the 'locator.doorsandskustatus' method is called.
    * Otherwise, the default method 'locator.doorsandevents' is called instead.
    * The 'locator.doorsandskustatus' method returns a 'door_inventory' with sku info in the door data.
    * The 'locator.doorsandevents' will only return door data.
    * The query also handles an onFailure and onSuccess callback.
    * If successful, it will return the result along with filtering the data and setting the class var data.
  */
  that.query = function(args) {
    var that = this;
    var _args = args || {};
    var method = 'locator.doorsandevents';

    // Override method with locator.doorsandskusstatus when skus are passed.
    try {
      if (_args.params[0] && _args.params[0].skus) {
        method = 'locator.doorsandskustatus';
      }
    }
    catch(err) {
    }


    generic.jsonrpc.fetch({
      method: method,
      params: _args.params,
      onSuccess: function(r) {
        if ( _args.onSuccess && typeof _args.onSuccess == 'function' ) {
          var result = r.getValue();
          result.params = _args.params;
          result.sorted = that.filterResults(result, _args);
          result.results = that.updateResults(result);
          if (Drupal.settings.globals_variables.enable_partner_count) {
            result.partnerCount = that.partnerStoreCount(result.results);
            result.partnerTypeValue = that.storeTypePartner;
          }
          that.data = result;
          _args.onSuccess(result);
        }
      },
      onFailure: function (r) {
        if ( _args.onFailure && typeof _args.onFailure == 'function' ) {
          _args.onFailure(r);
        }
      }
    });
  };

  that.partnerStoreCount = function(partnerData) {
    var partnerStoreCount = 0;
    _.filter(partnerData, function(door) {
      if (door.STORE_TYPE === that.storeTypePartner) {
        partnerStoreCount++;
      }
    });
    return partnerStoreCount;
  };

  that.updateResults = function( rawResultData ) {
    if (!rawResultData || !rawResultData.results) {
      return null;
    }
    var modResults = $.extend({}, rawResultData.results);
    for (var result in modResults) {
      if (modResults[result] && modResults[result].DISTANCE) {
        var distance = modResults[result].DISTANCE;
        var match = distance.match(/\d*.\d/);
        if ( match ) {
          modResults[result].distance_short = parseFloat(match[0]);
        }
      }
    }
    return modResults;
  };

  that.checkSkuAvailability = function (door, skuId, safetyStock) {
    var isAvailable = 0;
    if (door.skus_onhand && door.skus_onhand[skuId]) {
      if (door.skus_onhand[skuId]['available_qty'] - safetyStock > 0) {
        isAvailable = 1;
      }
    }
    return isAvailable;
  };


  /**
    * Method responsible for filtering the raw result data by distance.
    * It will iterate through all results and order them by using the door DISTANCE param.
    * First, if a maxDistance is passed, it will filter out doors over that limit.
    * Then it will sort the data by distance by creating a sortedDoorIds array to organize the doors.
`   * Once that list is created, the door data objects will be placed into a sortedResults.
    * If a doorLimit is passed, it will stop adding doors to the sortedResults.
    * Otherwise, it will use the array length as the limit.
  */
  that.filterResults = function( data, args ) {
    var that = this;
    var _args = args || {};
    var _resultData = $.extend({}, data);
    var skuId = data.params[0]['skus'][0];
    var safetyStock = data.params[0]['safetyStock'];

    if (data.door_inventory) {
      for (var d in data.door_inventory) {
        var door = data.door_inventory[d];
        // set inventory status for each door
        // door.skus_onhand[skuId]['is_available'] = that.checkSkuAvailability(door, skuId, safetyStock);
        // fold doors_inventory data into doors list
        _.extend(_resultData.results[d], door);
      }
    }
    //
    // filter out results that exceed max distance
    if (_resultData.maxDistance) { // max distance exists
      var filteredResults = {};
      for (var d in _resultData.results) {
        if (_resultData.results[d].DISTANCE < _resultData.maxDistance) {
          filteredResults[d] = data.results[d];
        }
      }
      _resultData.results = filteredResults;
    }

    // asc. sort by distance
    var sortedResults = [];
    var r  = _resultData.results ? _resultData.results : {};
    var sortedDoorIds = _resultData.doors || [];

    function sortByDistance( a, b ) {
      var aDist = (!r[a].DISTANCE || isNaN(r[a].DISTANCE)) ? 0 : parseFloat(r[a].DISTANCE);
      var bDist = (!r[b].DISTANCE || isNaN(r[b].DISTANCE)) ? 0 : parseFloat(r[b].DISTANCE);

      if ( aDist > bDist ) { return 1; }
      else if ( aDist < bDist ) { return -1; }
        return 0;
    }

    function sortByInvAndDistance( a, b ) {
      var aDist = (!r[a].DISTANCE || isNaN(r[a].DISTANCE)) ? 0 : parseFloat(r[a].DISTANCE);
      var bDist = (!r[b].DISTANCE || isNaN(r[b].DISTANCE)) ? 0 : parseFloat(r[b].DISTANCE);

      if (r[a].skus_onhand && r[b].skus_onhand) return sortByDistance(a,b);
      if (!r[a].skus_onhand && !r[b].skus_onhand) return sortByDistance(a,b);

      if (r[a].skus_onhand && !r[b].skus_onhand) return -1;
      if (!r[a].skus_onhand && r[b].skus_onhand) return 1;
    }

    // sortedDoorIds.sort(sortByInvAndDistance);
    sortedDoorIds.sort(sortByDistance);

    var params = ( data.params && data.params[0] ) ? data.params[0] : null;
    var doorLimit = params.display || sortedDoorIds.length;
    for (var i=0, len = parseInt(doorLimit); i<len; i++) {
        sortedResults.push(r[sortedDoorIds[i]]);
    }
    //
    // filter out results that are not free-standing stores
    var storeTypes = _args.inv_store_types || that.storeTypesReq;
    sortedResults = _.filter(sortedResults, function(door) {
      return storeTypes.indexOf(door.STORE_TYPE) > -1;
    });

    // console.log("......sorted");
    // console.log(sortedResults);
    return sortedResults;
  };

  /**
    * Helper method used to extract the sku inventory object from the internal data object.
    * You need to pass the doorId and skuId and that will return the inventory object for that sku and door.
    * When calling this method you'll get the current inventory data stored from the latest query.
    * If no door ID or SKU ID is passed, the function will return the full door_inventory object.
    * Here is an example of how the door_inventory object looks like.
    *     "429482": {
    *       "skus_onhand":{
    *         "L11R01": {"available_qty":116,"is_available":1,"last_polled_date":"11/6/2015 12:00:00 AM"}
    *       },
    *       "store_code":17504
    *     }
  */
  that.getDoorSkuInventoryData = function(args) {

    var _args = args || {};
    var doorId = _args.doorId ? _args.doorId.toString() : null;
    var skuId = _args.skuId ? _args.skuId.toString() : null;
    var callback = _args.callback;
    var inventoryData  = (that.data && that.data.door_inventory) ? that.data.door_inventory : null;

    // if neither id is passed, return the full set of data
    if (!doorId && !skuId) return inventoryData;

    var doorInventoryObj = (inventoryData && inventoryData[doorId]) ? inventoryData[doorId]: null;
    if ( !doorInventoryObj) {
      return null;
    }
    doorInventoryObj = ( doorInventoryObj && doorInventoryObj.skus_onhand ) ? doorInventoryObj.skus_onhand : null;
    var skuInventory = ( doorInventoryObj && doorInventoryObj[skuId] ) ? doorInventoryObj[skuId] : null;

    return skuInventory;
  };

  /**
    * Helper method to determine if a particular sku is available at a specific door.
    * If door data is available or not with for that sku and door, it will pass a boolean.
    * If data isn't available, it will pass null.
    * It uses the internal method getDoorSkuInventoryData to get the door inventory object.
    * NOTE: Back end determines is_available, is_unavailable and is_call.
  */
  that.isDoorSkuInStock = function( args ) {

    var callback = args.callback;
    var skuInventory = that.getDoorSkuInventoryData(args);

    var inStock = null;
    if ( skuInventory && skuInventory.is_available ) {
      inStock = true;
    } else if ( skuInventory && skuInventory.is_unavailable ) {
      inStock = false;
    }

    if (typeof callback == 'function') {
      callback(inStock);
    } else {
      return inStock;
    }
  };

  that.getSortedDoors = function () {
    if (!that.data || !that.data.sorted) return [];
    return that.data.sorted;
  };

  that.getDoorsWithInv = function () {
    if (!that.data || !that.data.door_inventory) return [];
    var doors = [];
    var i = 0;
    for (var id in that.data.door_inventory ) {
      doors.push(that.data.results[id]);
      doors[i] = _.extend(doors[i], that.data.door_inventory[id]);
      i++;
    }
    return doors;
  };

  that.getDoors = function (args) {
    if (!that.data) return null;
    var doors = [];
    if (!args) {
      for (var id in that.data.results) {
        doors.push(that.data.results[id]);
      }
      return doors;
    }

    var doorIds = [];
    if (!_.isArray(args)) {
      doorIds.push(args)
    } else {
      doorIds = args;
    }

    var filteredDoors = _.map(doorIds, function(id) {
      return that.data.results[id];
    });
    return filteredDoors;
  }

  /**
    * Helper method to get the last polled date for a particular sku with a specific door.
    * It uses the internal method getDoorSkuInventoryData to get the door inventory object.
    * If the date is available, it will return that date otherwise it will return null;
    * NOTE: Date format is also configurable on the back-end, so try there first before using this method.
  */
  that.getDoorSkuPolledDate = function(args) {

    var skuInventory = that.getDoorSkuInventoryData(args);

    var polledDate = (skuInventory && skuInventory.last_polled_date) ? skuInventory.last_polled_date : null;
    var date = polledDate ? polledDate.match(/([\w\d\/]*)/gi) : [];

    return date[0];
  };

  /**
   * Returns the regex to use for testing the user-entered zip/postcode
   * Should be set in the relevant domains/*.inc file but if not defaults
   * back to returning the value of that.defaultZipRegEx in this file
   *
   * @param {object} args The Drupal global variables (allows for easier unit testing :))
   * @return {object} RegExp object
  */
  that.getLocalisedZipRegEx = function(args) {
    if (typeof args.prod_store_check_zipcode_regex !== 'undefined') {
      try {
        return new RegExp(args.prod_store_check_zipcode_regex, 'i');
      } catch (e) {
        // swallow any invalid regular expression pattern errors for now
      }
    }

    return new RegExp(that.defaultZipRegEx);
  };

  /**
    * Default query fields for the doors and inventory queries.
  */
  that.queryFields = "DOOR_ID, DOORNAME, STORE_HOURS, ADDRESS, ADDRESS2, STATE_OR_PROVINCE, CITY, REGION, COUNTRY, ZIP_OR_POSTAL, PHONE1, STORE_TYPE, LONGITUDE, LATITUDE, JDA_STORE_NUMBER, STORE_TYPE, SUB_HEADING";

  /**
    * Default query fields for the doors queries only.
  */
  that.queryDoorsFields = "DOOR_ID";

  return that;
}());
